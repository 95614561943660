<template>
  <div class="actions">
    <a
      class="btn btn-success btn-sm"
      v-if="!active && is_admin"
      @click.prevent="activating(true)"
    > <i class="icofont icofont-play" /></a>
    <a
      class="btn btn-warning btn-sm"
      v-if="active && is_admin"
      @click.prevent="activating(false)"
    > <i class="icofont icofont-pause" /></a>
    <router-link
      :to="{name: 'edit_user', params: {uid: object.uid}}"
      class="btn btn-primary btn-sm"
      v-if="canUpdate"
    >
      <i class="icofont icofont-pencil" />
    </router-link>
    <a
      href="javascript:void(0)"
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
      v-if="is_admin"
    > <i class="icofont icofont-close" /></a>
  </div>
</template>
  
  
  
  <script>
  import { DELETE_USER, EDIT_USER } from '../../graphql/user';
  import {TYPE_PROPRIO} from '../../constants/app'
  import { mapGetters } from 'vuex';
  export default {
      props: {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
          activating(active){
            let data = {
              active
            }
            this.$apollo.mutate({
                mutation: EDIT_USER,
                variables: {
                    "updateUser": {
                        ...data
                    },
                    "uid": this.object.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`User ${this.object.fullname} updated successfully`)
                }
              })
          },
          deleting(){
            this.$apollo.mutate({
                  mutation: DELETE_USER,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`User ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting user ${this.object.uid}`)
                  }
                })
          }
      },
      computed: {
        ...mapGetters({
          is_super_admin: 'auth/is_super_admin',
          is_admin: 'auth/is_admin',
          is_veto: 'auth/is_veterinaire',
          typeComptes: 'auth/typeComptes'
        }),
        active(){
          if(this.object.active !== null) return this.object.active
          return false
        },
        isProprietaire(){
          let type = this.typeComptes.find(item => item.uid === this.object.typeCompte)
          if(type !== undefined) return type.code === TYPE_PROPRIO
          return false
        },
        canUpdate(){
          if(this.is_veto) return this.isProprietaire
          return this.is_admin
        }
      }
  
  }
  
  </script>
  <style lang="scss">
  .actions{
      a{
          
      }
  }
  </style>